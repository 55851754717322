export enum InputFieldType {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    PASSWORD = "password"
}

export enum LoaderColor {
    WHITE = 'WHITE',
    blue = 'blue',
    WARNING = 'WARNING'
}


export interface Logo {
    readonly id: string
    readonly name: string
    readonly size: string
    readonly meta: File
}