import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "px-6 h-24 flex items-center justify-between w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsDropdown = _resolveComponent("ClinicsDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeClinic?.logo)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "h-20 w-32 bg-contain bg-center bg-no-repeat rounded-md",
          style: _normalizeStyle({ 'background-image': `url(${_ctx.activeClinic?.logo})` })
        }, null, 4))
      : _createCommentVNode("", true),
    _createVNode(_component_ClinicsDropdown, {
      "show-label": false,
      class: "w-64 ml-auto",
      onOnClinic: _ctx.onClinic,
      onOnDisable: _ctx.onClinic,
      "initial-clinic-u-i-d": _ctx.activeClinicUID,
      "show-select-all": true
    }, null, 8, ["onOnClinic", "onOnDisable", "initial-clinic-u-i-d"])
  ]))
}