

import {defineComponent} from 'vue'
import ActivateClinic from "@/components/layout/ActivateClinic.vue";

export default defineComponent({
  components: {ActivateClinic},
  setup() {


    return {}
  }
})
