
import { computed, defineComponent } from "vue";
import ClinicsDropdown from "@/components/clinics/utils/ClinicsDropdown.vue";
import { useClinicsStore } from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "ActivateClinic",
  components: { ClinicsDropdown },
  setup() {
    const clinicStore = useClinicsStore();
    const clinicState = clinicStore.getState();
    const activeClinicUID = computed(() => clinicState.activeClinicUID);
    const activeClinic = computed(() =>
      clinicState.clinics.find((clinic) => clinic.uid === activeClinicUID.value)
    );
    const onClinic = (clinicUID?: string) =>
      clinicStore.setActiveClinicUID(clinicUID);
    return { onClinic, activeClinicUID, activeClinic };
  },
});
